export const modalData = {
	reglesPage: {
		title: "L'espace règle",
		description:
			"Retrouvez toutes nos astuces à un seul endroit. Entraînez-vous avec plus de 500 questions d'entraînement pour consolider vos connaissances.",
		bulletPoints: [
			'Retrouvez toutes nos astuces à un seul endroit',
			"Entrainez vous sur +500 questions d'entrainement",
			'Recherchez vos règles et retrouvez les exercices associés',
		],
		CTA: "Débloquer l'espace règle",
		CTALink:
			'https://www.orthographiq.com/offres/formation-orthographe?utm_source=app&utm_medium=modal&utm_campaign=se-rules-feature-blocked',
		heroImage: 'reglesPage.png',
	},
	assistantPage: {
		title: "L'assistant OrthographIQ",
		description:
			"Avec notre outil, détectez vos fautes d'orthographe en un seul clic. Vous bénéficierez également d'explications claires pour comprendre vos erreurs.",
		bulletPoints: [
			"Le correcteur qui détecte vos fautes d'orthographe en un seul clic",
			'Comprenez vos fautes grâce à nos explications',
			'Vos fautes nous permettent de personnaliser davantage la formation',
		],
		CTA: 'Débloquer l’assistant',
		CTALink:
			'https://www.orthographiq.com/offres/formation-orthographe?utm_source=app&utm_medium=modal&utm_campaign=se-assistant-feature-blocked',
		heroImage: 'assistantPage.png',
	},
	CTAMobile: "Retour à l'espace personnel",
	CTAMobileLink: '/dashboard/profile',
};
