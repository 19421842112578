<template>
	<v-row>
		<v-col v-for="(element, index) in textSavedDisplay" :key="index" cols="6" md="2">
			<v-card class="pa-2 d-flex flex-column justify-space-between" outlined rounded tile style="height: 14rem">
				<p v-html="tronc(element.text)" class="mb-2"></p>
				<v-btn small color="error" @click="deleteText(element._id)">Supprimer</v-btn>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'TextsSaved',
	props: {
		formationId: String,
	},
	computed: {
		...mapGetters('assistant', {
			textsSaved: 'textsSaved',
		}),
	},
	data() {
		return {
			textSavedDisplay: [],
		};
	},
	async mounted() {
		await this.$store.dispatch('assistant/getTextsSaved', {
			formationId: this.formationId,
		});
		this.textSavedDisplay = this.textsSaved;
	},
	methods: {
		tronc(value) {
			value = value.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ');
			if (value.length > 120) {
				return value.slice(0, 120) + '...';
			} else {
				return value;
			}
		},
		async deleteText(textId) {
			const deletedText = await this.$store.dispatch('assistant/deleteTextsSaved', {
				textId,
			});
			if (deletedText.status == 200) {
				await this.$store.dispatch('assistant/getTextsSaved', {
					formationId: this.formationId,
				});
				this.textSavedDisplay = this.textsSaved;
			}
		},
	},
};
</script>

<style></style>
