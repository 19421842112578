<template>
	<Correcteur />
</template>

<script>
import Correcteur from './Correcteur';

export default {
	name: 'Assistant',
	components: {
		Correcteur,
	},
	data() {
		return {
			showAssistant: false,
		};
	},
};
</script>

<style></style>
