<template>
	<v-row justify="center">
		<v-dialog v-model="displayCondition" persistent content-class="restrict-user-dialog">
			<v-card id="restrict-user-card">
				<div class="modal-image">
					<v-img width="40%" :src="getImgUrl(data[modalName].heroImage)"></v-img>
				</div>
				<div class="modal-content">
					<v-card-title class="card-title text-h3 font-weight-black"> {{ data[modalName].title }} </v-card-title>
					<v-card-text class="modal-description">{{ data[modalName].description }}</v-card-text>
					<v-card-text class="modal-detail">
						<ul>
							<li v-for="(point, index) in data[modalName].bulletPoints" :key="index">
								<v-icon size="20px" class="mr-1" color="black">mdi-checkbox-multiple-marked-circle</v-icon>
								{{ point }}
							</li>
						</ul>
					</v-card-text>
					<v-card-actions class="modal-actions">
						<v-btn color="black" x-large dark class="modal-button" :href="data[modalName].CTALink" target="_blank">
							<b>{{ data[modalName].CTA }}</b>
						</v-btn>
						<router-link :to="data.CTAMobileLink" class="text-center mobile-cta">{{ data.CTAMobile }}</router-link>
					</v-card-actions>
				</div>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { modalData } from './data.js';
import { getImgUrl, setZindexByClassNames } from '../../../utils/helper.js';

export default {
	name: 'ModalForRestrictedUser',
	props: {
		modalName: String,
		testTrialData: String,
	},
	data: () => {
		return {
			data: modalData,
		};
	},
	computed: {
		displayCondition() {
			return this.testTrialData ? true : false;
		},
	},
	mounted: function () {
		if (window.innerWidth >= 960) {
			setZindexByClassNames('navbar', 210); // 210 is higher enough than v-dialog
			setTimeout(() => setZindexByClassNames('trial-finished-alert', 210), 900);
		}
	},
	destroyed: function () {
		setZindexByClassNames(['navbar', 'trial-finished-alert'], 0);
	},
	methods: {
		getImgUrl,
		setZindexByClassNames,
	},
};
</script>

<style lang="scss" scope>
.restrict-user-dialog {
	max-width: 55%;
	overflow-y: hidden;
}

#restrict-user-card {
	.card-title {
		word-break: keep-all;
		padding-bottom: 10px;
		padding-top: 5px;
	}

	.modal-image {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 14px 0 14px 0;
		background-color: #34e5c8;
	}

	.modal-content {
		padding: 14px 30px 32px 30px;
	}

	.modal-description {
		font-size: 16px;
		line-height: 1.4;
		padding-bottom: 10px;
	}

	.modal-detail {
		padding: 5px 16px;
		line-height: 2;
		font-size: 16px;
		ul {
			list-style: none;
			padding-left: 0;
		}
	}

	.modal-actions {
		justify-content: end;

		.modal-button {
			padding: 24px 32px;
			font-size: 18px;
			border-radius: 10px;
			text-transform: none;
		}
	}

	.mobile-cta {
		display: none;
		font-size: 1rem;
		margin-top: 10px;
		color: #9d9da6;
		font-weight: 500;
	}
}

@media screen and (min-width: 1200px) {
	.restrict-user-dialog {
		margin-top: 100px;
		max-height: 85% !important;
	}
}

@media screen and (min-width: 950px) {
	.restrict-user-dialog {
		margin-top: 140px;
	}
}

@media screen and (min-width: 1200px) and (max-height: 820px) {
	.v-image {
		width: 21% !important;
	}
}

@media screen and (min-width: 760px) and (max-height: 755px) {
	.v-image {
		width: 10% !important;
	}
}

@media screen and (min-width: 760px) and (max-height: 500px) {
	.v-image {
		display: none;
	}
}

@media screen and (min-width: 800px) and (max-height: 410px) {
	.restrict-user-dialog {
		max-height: 100% !important;
	}
}

@media screen and (max-width: 959px) {
	.restrict-user-dialog {
		max-width: 75%;
	}
	#restrict-user-card {
		.modal-content {
			padding: 14px;
		}
		.modal-actions {
			flex-direction: column;
			justify-content: center;
		}
		.mobile-cta {
			display: block;
		}
	}
}

@media screen and (max-width: 790px) {
	.restrict-user-dialog {
		max-width: 90%;
		margin: 0;
	}

	#restrict-user-card {
		.v-card__title {
			line-height: 1.5rem !important;
			font-size: 1.5rem !important;
		}

		.modal-description {
			display: none;
		}

		.modal-actions {
			.modal-button {
				padding: 20px 32px;
				font-size: 16px;
				width: 70%;
				height: 40px;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	#restrict-user-card {
		.modal-content {
			padding: 8px;
		}

		.modal-detail {
			line-height: 1.6;
		}

		.modal-actions {
			.modal-button {
				width: 100%;
				border-radius: 6px;
			}
		}
	}
}
</style>
